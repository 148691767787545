<template>
  <el-form>
    <el-form-item label="id" v-if="editingExtension._id">
      {{ editingExtension._id }}
    </el-form-item>
    <el-form-item label="名称">
      <el-input v-model="editingExtension.name"></el-input>
    </el-form-item>
    <el-form-item label="版本">
      <el-input v-model="editingExtension.version"></el-input>
    </el-form-item>
    <el-form-item label="类型">
      <el-tag
        v-for="(type, $index) in editingExtension.types"
        :key="type"
        closable
        @close="handleClose(editingExtension.types, $index)">{{ type }}</el-tag>
      <br>
      <el-select v-model="newType" placeholder="类型">
        <el-option
          v-for="type in extensionTypes"
          :key="type.value"
          :label="type.name"
          :value="type.value"></el-option>
      </el-select>
      <el-button @click="editingExtension.types.push(newType)">添加</el-button>
    </el-form-item>
    <el-form-item label="游戏版本">
      <el-tag
        closable
        v-for="(v, $index) in editingExtension.supportV"
        :key="v"
        @close="handleClose(editingExtension.supportV, $index)">{{ v }}</el-tag>
      <br>
      <el-button-group>
        <el-button
          v-for="version in alternativeVersions"
          :key="version"
          size="small"
          @click="editingExtension.supportV.push(version)">add {{ version }}</el-button>
      </el-button-group>
    </el-form-item>
    <el-form-item label="端">
      <el-tag
        closable
        v-for="(side, $index) in editingExtension.sides"
        :key="side"
        @close="handleClose(editingExtension.sides, $index)">{{ side }}</el-tag>
      <br><br>
      <el-button-group>
        <el-button
          size="small"
          @click="editingExtension.sides.push('server')">add server</el-button>
        <el-button
          size="small"
          @click="editingExtension.sides.push('client')">add client</el-button>
      </el-button-group>
    </el-form-item>
    <el-form-item label="描述">
      <el-input
        v-model="editingExtension.description"
        type="textarea"
        autosize></el-input>
    </el-form-item>
    <el-form-item label="上传">
      <Uploader
        :on-success="onUploaded"
        :on-hash-progress="onHashCalculated"
        :resumable="true"
        :dest-dir="dir">
        <el-button size="small">点击上传</el-button>  
      </Uploader>
    </el-form-item>
    <el-form-item label="URL">
      <el-input v-model="editingExtension.downloadUrl"></el-input>
    </el-form-item>
    <el-form-item label="SHA1">
      <el-input v-model="editingExtension.hash"></el-input>
    </el-form-item>
    <el-form-item label="自动解析">
      <el-button :loading="parsing" @click="parse">Parse Download URL</el-button>
    </el-form-item>
    <el-form-item label="前置">
      <el-tag
        closable
        v-for="(id, $index) in editingExtension.dependencies"
        @close="handleClose(editingExtension.dependencies, $index)"
        :key="id">{{ id }}</el-tag>
      
      <el-autocomplete
        v-model="depQuery"
        @select="handleDepClick"
        :fetch-suggestions="querySearch"
        placeholder="name"></el-autocomplete>
    </el-form-item>
    <el-form-item label="冲突">
      <el-tag
        closable
        v-for="(id, $index) in editingExtension.conflicts"
        @close="handleClose(editingExtension.conflicts, $index)"
        :key="id">{{ id }}</el-tag>

      <el-autocomplete
        v-model="conflictQuery"
        @select="handleConflictClick"
        :fetch-suggestions="querySearch"
        placeholder="name"></el-autocomplete>
    </el-form-item>
    <el-form-item>
      <el-button
        @click="submit"
        :loading="loading">{{ newOne ? '新建' : '更新' }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { updateExtension, createExtension, getExtensions, parseJar } from '@/api'
import { showSuccess, showError, extensionTypes, alternativeVersions } from '@/utils'
import Uploader from '@/components/Uploader'
import { useTask } from '@/composables'

export default {
  props: {
    extension: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup (props) {
    const { addTask } = useTask()
    const editingExtension = ref({})
    const loading = ref(false)
    const newExtension = {
      name: '',
      version: '',
      supportV: ['1.12.2', '1.16.5'],
      sides: ['server', 'client'],
      description: '',
      hash: '',
      downloadUrl: '',
      type: '',
      types: [],
    }
    const depQuery = ref('')
    const conflictQuery = ref('')
    const parsing = ref(false)
    const newType = ref('')

    const dir = computed(() => {
      for (const type of extensionTypes.values()) {
        if (editingExtension.value.types.includes(type.value)) {
          return type.dir
        }
      }

      return ''
    })

    const newOne = computed(() => {
      if (props.extension.name) {
        return false
      }

      return true
    })

    watch(props, () => {
      init()
    })

    const handleClose = function (arr, index) {
      arr.splice(index, 1)
    }

    const handleDepClick = function (item) {
      if (item._id) {
        if (editingExtension.value.dependencies) {
          editingExtension.value.dependencies.push(item._id)          
        } else {
          editingExtension.value.dependencies = [item._id]
        }
        depQuery.value = ''
      }
    }

    const handleConflictClick = function (item) {
      if (item._id) {
        if (editingExtension.value.conflicts) {
          editingExtension.value.conflicts.push(item._id)          
        } else {
          editingExtension.value.conflicts = [item._id]
        }
        conflictQuery.value = ''
      }
    }

    const querySearch = async function (query, callback) {
      try {
        const extensions = await getExtensions('', 1, { name: query })
        const items = extensions.filter(extension => extension.status !== 'deleted').map(extension => {
          return {
            value: `${extension.name}(${extension.version},${extension.supportV})`,
            _id: extension._id,
          }
        })
        callback(items)
      } catch (err) {
        console.error(err)
        showError(err)
      }
    }

    const submit = async function () {
      loading.value = true
      try {
        if (newOne.value) {
          await createExtension(editingExtension.value)
        } else {
          await updateExtension(editingExtension.value._id, {
            name: editingExtension.value.name,
            version: editingExtension.value.version,
            type: editingExtension.value.type,
            supportV: editingExtension.value.supportV,
            description: editingExtension.value.description,
            downloadUrl: editingExtension.value.downloadUrl,
            hash: editingExtension.value.hash,
            dependencies: editingExtension.value.dependencies,
            conflicts: editingExtension.value.conflicts,
            types: editingExtension.value.types,
            sides: editingExtension.value.sides,
          })
        }
        showSuccess('提交成功')
      } catch (err) {
        console.error(err)
        showError(err)
      } finally {
        loading.value = false
      }
    }

    const onHashCalculated = function (hash) {
      editingExtension.value.hash = hash
    }

    const onUploaded = async function (url) {
      editingExtension.value.downloadUrl = url
    }

    const parse = async function () {
      try {
        if (editingExtension.value.downloadUrl === '') {
          throw new Error('empty download url')
        }

        if ([
          'plugin_sponge',
          'plugin_spigot',
          'mod_forge',
          'mod_fabric',
          'plugin_nukkit',
          'plugin_bungeecord'].includes(editingExtension.value.type)) {
          const task = await parseJar(editingExtension.value.downloadUrl)
          parsing.value = true

          addTask(task, (err, result) => {
            parsing.value = false

            if (err) {
              return showError(err)
            }

            //"{"package":"yuushya","name":"Yuushya","authors":["Cocofish or 几何","Xiao2 or XiLaiTL"],"mcversion":"1.16.x","deps":[],"hash":"c933064417864f569d384935509f7f314ba666f8","type":"mod_fabric"}"
            console.log(result)
            if (result.name) {
              editingExtension.value.name = result.name
            }

            if (result.version) {
              editingExtension.value.version = result.version
            }

            if (result.description) {
              editingExtension.value.description = result.description
            } else if (result.name) {
              editingExtension.value.description = result.name
            }

            if (result.mcversion) {
              if (result.mcversion.includes('1.16')) {
                editingExtension.value.supportV = ['1.16.5']
              }

              if (result.mcversion.includes('1.12')) {
                editingExtension.value.supportV = ['1.12.2']
              }
            }
          })
        }
      } catch (err) {
        showError(err)
      }
    }

    const init = function () {
      if (newOne.value) {
        editingExtension.value = newExtension
      } else {
        editingExtension.value = props.extension
      }
    }

    init()

    return {
      editingExtension,
      newOne,
      handleClose,
      submit,
      handleDepClick,
      handleConflictClick,
      querySearch,
      depQuery,
      conflictQuery,
      loading,
      dir,
      onHashCalculated,
      onUploaded,
      alternativeVersions,
      parse,
      parsing,
      extensionTypes,
      newType,
    }
  },
  components: {
    Uploader,
  }
}
</script>

<style scoped>
.el-tag {
  margin-left: 10px;
}
</style>