<template>
  <div class="extensions">
    <el-row type="flex" justify="center">
      <el-col class="batch-actions" :span="18">
        <el-input placeholder="批量替换 extension id" v-model="extensionIdToBatch">
          <template #append>
            <el-button :loading="replacing" @click="replaceExtension">批量替换</el-button>
          </template>
        </el-input>
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <el-row type="flex" justify="center">
      <el-col :span="18">
        <el-input
          v-model="query"
          placeholder="search extension"
          @change="fetch">
          <template #prepend>
            <el-select v-model="type" placeholder="类型">
              <el-option
                v-for="type in extensionTypes"
                :key="type.value"
                :label="type.name"
                :value="type.value"></el-option>
            </el-select>
          </template>

          <template #append>
            <el-button :loading="fetching" :icon="Search" @click="fetch"></el-button>
          </template>
        </el-input>

        <el-button class="add" @click="edit({})">新增</el-button>

        <el-table :data="extensions">
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="类型" prop="type"></el-table-column>
          <el-table-column label="端" prop="sides"></el-table-column>
          <el-table-column label="description">
            <template #default="scope">
              {{ scope.row.description.substr(0, 10) }}
            </template>
          </el-table-column>
          <el-table-column label="status" prop="status"></el-table-column>
          <el-table-column label="supportV" prop="supportV"></el-table-column>
          <el-table-column label="public">
            <template #default="scope">
              <el-switch
                v-model="scope.row.public"
                :loading="scope.row.updatingPublic"
                :before-change="togglePublicFactory(scope.row)"
                ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="110">
            <template #default="scope">
              <el-button-group>
                <el-button @click="edit(scope.row)">编辑</el-button>
                <el-button type="danger" @click="remove(scope.row)">删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          v-model:currentPage="page"
          layout="prev, pager, next"
          :page-size="10"
          @prev-click="page--"
          @next-click="page++"
          :total="1000">
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog :title="editingExtension.name" v-model="showDialog">
      <extension-form :extension="editingExtension"></extension-form>
    </el-dialog>
  </div>
</template>

<script>
import ExtensionForm from '@/components/ExtensionForm.vue'
import { useExtension } from '@/composables'
import { Search } from '@element-plus/icons'
import { extensionTypes } from '@/utils'

export default {
  setup() {
    return {
      ...useExtension(),
      Search,
      extensionTypes,
    }
  },
  components: {
    ExtensionForm
  }
}
</script>

<style scoped>
.el-select {
  width: 180px;
}

.add {
  margin-top: 1em;
  float: right;
}

.batch-actions {
  margin-bottom: 1em;
}
</style>